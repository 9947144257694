<footer>
  <div class="footer-content left-content">
    <img src="/assets/images/logo.png" class="logo" alt="">
    <div class="link-container">
      <a class="link" href="https://wa.me/6281318794988">
        <img src="/assets/images/icons/wa.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Whatsapp' | translate }}</p>
          <p>+6281318794988</p>
        </div>
      </a>
      <a class="link" href="mailto:info@addtix.id">
        <img src="/assets/images/icons/mail.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Email' | translate }}</p>
          <p>info@addtix.id</p>
        </div>
      </a>
    </div>
    <div class="link-container mt-3">
      <a class="link" >
        <img src="/assets/images/icons/address.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Address' | translate }}</p>
          <p>Epiwalk Office Suite Level 5 Unit 529A,
            Jl. H. R. Rasuna Said, Jakarta 12940</p>
        </div>
      </a>
    </div>
  </div>
  <div class="footer-content center-content">
    <h3>{{ 'Company' | translate }}</h3>
    <a [routerLink]="['/about-us']">{{ 'About Us' | translate }}</a>
  </div>
  <div class="footer-content center-content">
    <h3>{{ 'Customer Care' | translate }}</h3>
    <a [routerLink]="['/faq']">{{ 'FAQs' | translate }}</a>
    <!-- <a [routerLink]="['/privacy-policy']">{{ 'Privacy & Policy' | translate }}</a> -->
    <!-- <a [routerLink]="['/terms-condition']">{{ 'Terms of Use' | translate }}</a> -->
  </div>
  <div class="footer-content right-content">
    <h3>{{ 'Security of Your Transaction' | translate }}</h3>
    <div class="brand-container">
      <img src="/assets/images/brands/1.svg" alt="">
      <img src="/assets/images/brands/2.svg" alt="">
      <img src="/assets/images/brands/3.svg" alt="">
      <img src="/assets/images/brands/4.svg" alt="">
      <img src="/assets/images/brands/5.svg" alt="">
    </div>
  </div>
</footer>
