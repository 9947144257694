import { Injectable } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  source = null
  constructor(
    private aR: ActivatedRoute,
  ) {
    this.aR.queryParams
      .subscribe(params => {
          this.source = params['source'];
        }
      );
  }

  initSource(){
    if(!this.source){
      this.source = this.aR.snapshot.queryParamMap.get('source');
    }
  }

  get isSukha(){
    return this.source === 'mandiri_sukha';
  }
}
